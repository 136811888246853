<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Notification Category</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
              <!-- <CCol md="2">
                   <b-button variant="primary" to="/notif/create/category" >Add New</b-button>                    
              </CCol>              -->
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 
            <!-- <CRow>
                 <b-col sm="6">
                        <b-form-checkbox
                          id="cbstatus"
                          v-model="cbstatus"
                          name="cbstatus"                          
                          @change="onModifyClick"
                        >
                         Disabled List
                        </b-form-checkbox>
                        </b-col>
            </CRow><br/>            -->

          <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
          </CRow>

          <div class="text-center">
             <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>

          <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

            <template v-slot:cell(createDate)="{ item }"> 
                 {{ item.createDate | dateWithTime }}
            </template>

            <template v-slot:cell(mandatory)="{ item }"> 
                 {{ item.mandatory | setlabel }}
            </template>

            <template v-slot:cell(actions)="{ item }">            

                <b-dropdown variant="info" text="Actions" size="md">   
                <!-- <router-link to="#" exact v-on:click.native="ChangeToBlock(item.userName)" tag="b-dropdown-item" > Block </router-link> -->
                <router-link :to='"/notif/edit/category/" + item.code' tag="b-dropdown-item" > Edit </router-link>
                </b-dropdown> 

            </template>

        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AccountService from '@/api/AccountService.js'; 
import moment from 'moment';

 export default {
    name: "users",
    data() { var date = new Date();
      return {   
        cbstatus:false,
        type : "active",
        items:[],             
        fields: [   
          {            
            key: 'code',
            label : 'Code',
            sortable: true
          },  
          {
            key: 'description',
            label : 'Description',
            sortable: true
          },   
          {
            key: 'mandatory',
            label : 'Mandatory',
            sortable: true
          },         
        
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }       
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:'', 
        spinner:false,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        passOnlyString(value){
           if(!value) return ''
           value = value.toString()
           return value.replace(/[^a-zA-Z ]/g, "")
        },  
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        setlabel(value){
           if(value) return "Yes"
           else return "No"     
        }
      },

    created () {  
     
      this.getNotifCategoryList();   

    },
    methods: { 

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },   
      
      onModifyClick(){
          this.spinner = true;
          var status   = this.cbstatus ? "disabled" : "active";  //console.log(status); 
          if(status === "disabled"){
             this.getDisabledNotifCategoryList();
          } else {
             this.getNotifCategoryList();
          }
         
      },
      
      getNotifCategoryList: function() {
        AccountService.getNotifCategoryList().then(resp => {  //console.log(resp);
           this.items = resp; 
        }, error => {
          this.spinner = false;
        });
      },

      getDisabledNotifCategoryList: function() {
        AccountService.getDisabledNotifCategoryList().then(resp => {  console.log(resp);
           this.items = resp; 
        }, error => {
          this.spinner = false;
        });
      }

    }    
 }; 

</script>
<style>
    .table{ hoverflow-y:scroll; }
</style>